(function($){

  // ---------- START
  $.widget('bsh.blocklist', {

    _create: function() {
			var $this = this;
			this.target_list  = $('#blockslist');
			this.target_count = $('#results');
			this.target_pages	= $('#list-pagination');
			this.base_url 	  = this.target_list.data('url') + '.json';

			// Instantiate
			this._initFilters();
			this._initToggles();
			this._initMap();
    },

		/* Filters
		----------------------------------*/
		_initFilters: function () {
			var $this = this;
			var $select = $('.filter');
			var $filters = {};

			$select.each (function (el, index) {
				$filters[$(this).attr('name')] = 0;
				$(this).on('change', function(e) {
					var $url = '/' + $this.base_url;
					var $val = $(this).val() > 0 ? $(this).val():0;
					$filters[$(this).attr('name')] = $val;
					for (var key in $filters) {
						$url += '/' + key + ':' + $filters[key];
					}
					$this._ajaxLoad($url);
				});
			});

			this._ajaxLoad(this.base_url);
		},

		/* Toggle cards
		----------------------------------*/
		_initToggles: function () {
			var $this = this;
			var $items = $('.card');

			$items.each (function (index, el) {
				$(this).data('id', index);
				$(this).on('click', function() {
					$items.not(this).removeClass('active');
					$items.not(this).find('.card-metas').attr('aria-hidden', 'true');
					
					//console.log($(this));
					if (!$(this).hasClass('active')) {
						$(this).addClass('active');
						$(this).find('.card-metas').attr('aria-hidden', 'false');
						$this.centerOnMarker(index);
					} else {
						$(this).removeClass('active');
						$(this).find('.card-metas').attr('aria-hidden', 'true');
						$this.updateMap();
					}
				});
			});
		},

		/* Ajax load listings and init actions
		----------------------------------*/
		_ajaxLoad: function(load_url) {
			var $this = this;
			this.target_list.addClass('hide');

			$.ajax({
				url: load_url,
				method: 'get',
				dataType: 'json',
				success: function(response) {
					$this.target_list.html(response.cards).removeClass('hide');
					$this.target_count.html(response.results);
					$this.target_pages.html(response.pagination);
					resetPaginate();
					$this._initToggles();
					$this.updateMap();
					$this.resetList();
				}
			});

			function resetPaginate () {
				$('.pagination-list a').on('click', function(e) {
					e.preventDefault();
					$this._ajaxLoad($(this).attr('href'));
				});
			}
		},

		/* Map
		----------------------------------*/
		_initMap: function () {
			var $this = this;
			var $maps = document.querySelectorAll('.js-map');
			if ($maps.length) {
				$maps.forEach(function($map) {
					var $mapView = $map.querySelector('.js-map-view');
					$this.map = map = L.map($mapView, {
						center: [48.18922, -2.91275],
						zoom: 10,
						minZoom: 7,
						maxZoom: 18,
						scrollWheelZoom: false,
						dragging: !L.Browser.mobile
					});

					L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png').addTo(map);
					//$this.markersLayer = markersLayer = new L.LayerGroup();
					$this.markersCluster = markersCluster = new L.MarkerClusterGroup();
					markersCluster.addTo(map);

					$this.updateMap();
				});
			}
		},

		updateMap: function () {
			
			var $this = this;
			$this.item_selected = null;
			$this.markers = markers = [];
			$this.markersCluster.clearLayers();

			var $mapLocations = document.querySelectorAll('.card');
			var icon = L.icon({
				iconUrl: '/assets/images/layout/pin.svg',
				iconSize: [30, 34],
				iconAnchor: [15, 34],
			});
			var overicon = L.icon({
				iconUrl: '/assets/images/layout/pin-over.svg',
				iconSize: [30, 34],
				iconAnchor: [15, 34],
			});

			$mapLocations.forEach(function($location, index) {

				var coords = $location.getAttribute('data-gps').split(',');

				var marker = L.marker([coords[0], coords[1]], {
					icon: icon,
					title: $location.getAttribute('data-title'),
					$obj: $location
				});

				marker.on('mouseover',function(ev) {
					marker.setIcon(overicon);
				});

				marker.on('mouseout',function(ev) {
					if($this.item_selected != marker)
						marker.setIcon(icon);
				});

				marker.on('click',function(e) {
					if($this.item_selected)
						$this.item_selected.setIcon(icon);
					marker.setIcon(overicon);
					toggleCard (this.options.$obj);
					$this.item_selected = marker;
				});

				markers.push(marker);

				//$this.markersLayer.addLayer(marker);
				
				$this.markersCluster.addLayer(marker);
			});

			var refit = function(map, markers) {
				var group = new L.featureGroup(markers);
				$this.map.fitBounds(group.getBounds(), {padding: [10, 10], maxZoom: 10});
			};

			if(markers.length)
				refit($this.map, markers);

			window.addEventListener('resize', function() {
				refit($this.map, markers);
			});

			function toggleCard (obj) {
				var $item = $(obj);
				$this.target_list.find('.active').removeClass('active');
				$item.addClass('active');

				var $top = $this.target_list.scrollTop() - $this.target_list.offset().top + $item.offset().top;
				$('.list-scroll').animate({scrollTop: $top});
			}
		},

		centerOnMarker: function (marker_id) {
			var latLngs = [this.markers[marker_id].getLatLng()];
			var markerBounds = L.latLngBounds(latLngs);
			this.map.flyToBounds(markerBounds, {'duration':1, padding: [70, 70], maxZoom: 14,});
		},

		resetList: function () {
			$('.list-scroll').scrollTop(0);
			
			// fragment url (search)
			var dataId = window.location.hash.substring(1);
			if (dataId !== '') {
				
				var topOffset=$(".page-map").offset().top;
				$('html, body').animate({
					scrollTop: topOffset-170
				}, 1000);
				
				var target = $('[data-id="' + dataId + '"]');
				// console.log(target);
				if (target.length) {
					target.trigger('click');
					var container = $('.list-scroll');
					var containerTop = container.offset().top;
					var targetTop = target.offset().top;
					var scrollTop = targetTop - containerTop + container.scrollTop();
					container.scrollTop(scrollTop);
				}
			}	
				  
			var url = window.location.href.split('#')[0];
			if (history.replaceState) {
				window.history.replaceState({}, document.title, url);
			} else {
			window.location.hash = '';
			} 
			
		}

  });
  // ---------- END

})(jQuery);
 