;(function($) {

// ---------- APP START
window.App = {

	config: {
		scrollOffset: 50
	},

	windowWidth : $(window).width(),
	bpScroll : 45,

  init: function() {


	if($('body').hasClass('ressources')) {
		var dataId = window.location.hash.substring(1);
		if (dataId !== '') {
			$('#' + dataId).children().children().css('border', '1px solid #4cb8ad');
			var topOffset=$('#' + dataId).offset().top;
			$('html, body').animate({
				scrollTop: topOffset-170
			}, 1000);
		}		  
		var url = window.location.href.split('#')[0];
		if (history.replaceState) {
			window.history.replaceState({}, document.title, url);
		} else {
			window.location.hash = '';
		} 
	}

	$('#search-filters .tags-filter a').on('click', function() {
		var filterClass = $(this).attr('class');
	
		$('#search-filters .tags-filter').removeClass('m--active');
		$(this).parent().addClass('m--active');

		if(filterClass == 'all') {
		  $('#search_results_blocks').children('section.pg__section').show();
		}
		else {
		  $('#search_results_blocks').children('section:not(.' + filterClass + ')').hide();
		  $('#search_results_blocks').children('section.' + filterClass).show();
		}
		return false;
	  });


    $('a[href^="#"]').on('click', function(e){
      e.preventDefault();

      var scrollTo = $(this).data('scroll-to') ? $(this).data('scroll-to') : $(this).attr('href');

      if($(scrollTo).length > 0 && $(this).data('prevent-scroll') !== true)
        App.scroll(scrollTo, $(this).data('scroll-offset'));
    });

    if($('[data-scroll]').length){
      App.scroll($('[data-scroll]').data('scroll'));
    }

		App.initMenu();
		if($('.form-contact').length) App.initForms();
		if($('.blocks-list').length) $('.blocks-list').blocklist();
		if($('.ressources-filter').length) App.initRessourcesFilter();
		if($('.slider').length) App.initSlider();
		App.initSubscribe();
		App.initVideo();
		//App.initTracking();

		$(window).scroll(function() {
			var scroll = $(window).scrollTop();
			if (scroll >= 50) {
				$('.header').addClass('is-shrink');
			}
			else{
				$('.header').removeClass('is-shrink');
			}
		});

		$('.header').removeClass('is-shrink');

  },

	load: function() {
	},

  scroll: function(element, offset){
    offset = offset === undefined ? App.config.scrollOffset : offset;
    $('html, body').animate({ scrollTop: $(element).offset().top - offset}, 'slow');
  },

	initMenu: function() {
		// Toggle menu
		var FOCUSABLE_ELEMENTS = [
			'a[href]',
			'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
			'select:not([disabled]):not([aria-hidden])',
			'textarea:not([disabled]):not([aria-hidden])',
			'button:not([disabled]):not([aria-hidden])',
			'[tabindex]:not([tabindex^="-"])',
		];
		var el = document.querySelector('.js-menu');
		var focusableElements = el.querySelectorAll(FOCUSABLE_ELEMENTS);
		var firstFocusableElement = focusableElements[0];
		var lastFocusableElement = focusableElements[focusableElements.length - 1];
		var retainFocusRef = function(e) {
			App.retainFocus(e, firstFocusableElement, lastFocusableElement);
		};
		$('.js-menu-toggle').on('click', function() {
			if ($('.js-menu').attr('aria-hidden') === 'true') {
				$('.js-menu').attr('aria-hidden', 'false');
				$('body').addClass('is-nav-open');
				firstFocusableElement.focus();
				document.addEventListener('keydown', retainFocusRef);
			} else {
				$('.js-menu').attr('aria-hidden', 'true');
				$('.js-menu').one('animationend', function() {
          $('body').removeClass('is-nav-open');
        });
				document.removeEventListener('keydown', retainFocusRef);
			}
		});

		$('.js-menu-close').on('click', function() {
			$('.js-menu').attr('aria-hidden', 'true');
      $('.js-menu').one('animationend', function() {
        $('body').removeClass('is-nav-open');
      });
      document.removeEventListener('keydown', retainFocusRef);
		});

		$('.js-sub-menu').on('click', function() {
			var sb = $(this).find('.nav-submenu');
			sb.attr('aria-hidden', 'false');
			sb.addClass('is-subnav-open');
			$('.submenu-back').attr('aria-hidden', 'false');
			$('.submenu-back').addClass('active');
		});

		$('.submenu-back').on('click', function() {
			var sb = $('.nav-submenu[aria-hidden="false"]');
			sb.attr('aria-hidden', 'true');
			sb.one('animationend', function() {
				sb.removeClass('is-subnav-open');
			});
			$(this).attr('aria-hidden', 'true');
			$(this).one('animationend', function() {
				$(this).removeClass('active');
			});
		});

		$('.js-button-search').on('click', function(e) {
			if(!$(this).parent().hasClass('active')) {
				e.preventDefault();
				$(this).parent().toggleClass('active');
			}
		});
	},

	retainFocus: function(event, firstFocusableElement, lastFocusableElement) {
		var isTabPressed = event.key === 'Tab';
		if (!isTabPressed) {
			return;
		}
		if (event.shiftKey) {
			if (document.activeElement === firstFocusableElement) {
				lastFocusableElement.focus();
				event.preventDefault();
			}
		} else {
			if (document.activeElement === lastFocusableElement) {
				firstFocusableElement.focus();
				event.preventDefault();
			}
		}
	},

	initRessourcesFilter: function() {
		var $filter = $('.ressources-filter');
		var $select = $filter.find('.filter');
		var $filters = {};

		// OR
		/*$select.each (function (el, index) {
			$filters[$(this).attr('name')] = 0;
			$(this).on('change', function(e) {
				var $uri = '';
				var $val = $(this).val() > 0 ? $(this).val():0;
				$filters[$(this).attr('name')] = $val;
				for (var key in $filters) {
					$uri += '/' + key + ':' + $filters[key];
				}
				window.location.href = '/' + $filter.data('url') + $uri;
			});
		});*/

		// AND
		$select.on('change', function(e) {
			var $uri = '';
			$select.each (function (el, index) {
				var $val = $(this).val() > 0 ? $(this).val():0;
				$uri += '/' + $(this).attr('name') + ':' + $val;
			});
			window.location.href = '/' + $filter.data('url') + $uri;
		});
	},

	initVideo: function() {
		$('.js-video').magnificPopup({
			type: 'iframe',
			preloader: false,
		});
	},

	/* Sliders
	----------------------------------*/
	initSlider: function() {
		var $sliderNav = $('.slider__nav');
		var $slider 	 = $('.slider');
		$('.slider').slick({
			dots: false,
			arrows: true,
			infinite: false,
			slidesToShow: 3,
			slidesToScroll: 3,
			autoplay: false,
			responsive: [
				{
					breakpoint: 1156,
					settings: {
						slidesToShow: 2.2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 640,
					settings: {
						arrows: false,
						slidesToShow: 1.2,
						slidesToScroll: 1
					}
				}
			]
		});
	},

	/* Matomo
	----------------------------------*/
	conversionLaunch: function (action, params) {
		if (window._paq != undefined) {
		  _paq.push(['trackEvent', params.event_category, action, params.event_label]);
		}
	},

	initTracking: function() {
		$('.js-cta-call').on('click', function () {
			var phoneNumber = $('.js-cta-call-number').text();
			App.conversionLaunch('clic-generique-tel', {
				'event_category': 'clic-contact',
				'event_label': phoneNumber
			});
		});

		$('.js-agency-phone').on('click', function () {
			var phoneNumber = $(this).text();
			App.conversionLaunch('clic-agence-tel', {
				'event_category': 'clic-contact',
				'event_label': phoneNumber
			});
		});

		$('.js-agency-email').on('click', function () {
			var emailAddress = $(this).text();
			App.conversionLaunch('clic-agence-email', {
				'event_category': 'clic-contact',
				'event_label': emailAddress
			});
		});

		$('.js-agency-itinerary').on('click', function () {
			var agencyName = $(this).data('title');
			App.conversionLaunch('clic-agence-itineraire', {
				'event_category': 'clic-contact',
				'event_label': agencyName
			});
		});

		$('.js-cta-quotation-header').on('click', function () {
			App.conversionLaunch('clic-devis', {
				'event_category': 'clic-CTA',
				'event_label': 'demander un devis-header'
			});
		});

		$('.js-cta-quotation-product').on('click', function () {
			var productName = $(this).data('title');
			App.conversionLaunch('clic-devis', {
				'event_category': 'clic-CTA',
				'event_label': 'demander un devis-'+productName
			});
		});
	},

	initForms: function() {
		$('.form-contact [name=token]').val($('meta[name=refresh]').attr('content'));
	},

	initSubscribe: function() {
		$('.form-nl-subscribe').each(function (el) {
			var $url = '/nlsubscribe';
			var $target = $(this).find('.form-message');
			$(this).on('submit',function(e) {
					e.preventDefault();
					$(this).find('.form-submit').prop('disabled', true);
				$.ajax({
					url: $url,
					data: $(this).serialize(),
					method: 'post',
					success: function(response) {
						if(response.status === true) {
							$target.addClass('active').html('<span class="success">Félicitations. Vous êtes à présent abonné à notre newsletter</span>');
						}
						else {
							$target.addClass('active').html('<span class="failed">Une erreur est survenue. Retentez plus tard...</span>');
						}
						setTimeout(function(){
							$target.removeClass('active');
						},3600);
					}
				});
			});
		});
	},

	getHeights: function() {
		var vh = window.innerHeight * 0.01;
		$(':root').css({
			'--vh': vh + 'px',
			'--heightHeader': $('.header-inner').outerHeight() + 'px',
		});
	},
};
// ---------- APP END

// Init app
$(document).ready(App.init);
$(window).on('load', App.load); // jQuery 3
$(window).resize(App.resize);
})(jQuery);
